const socketIOClient = require("socket.io-client");
const sailsIOClient = require("sails.io.js");
const io = sailsIOClient(socketIOClient);

io.sails.url = process.env.REACT_APP_WEBSOCKET_URL || 'https://staging-secure.paulmueller.com';
io.sails.headers = {
  token: localStorage.getItem("token"),
};
io.sails.autoConnect = true;
io.sails.reconnection = true;
export default io;
