import axios from "axios";
import { logOut } from "./actions/actions";

const ROOT_URL = process.env.REACT_APP_CoreDataAPI;
const GRAPH_DATA_URL = process.env.REACT_APP_SensorDataAPI;

export const version = "4.3.9";

export const rootApi = axios.create({
  baseURL: ROOT_URL,
});

export const graphApi = axios.create({
  baseURL: GRAPH_DATA_URL,
});

rootApi.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token");
  config.headers.common["token"] = token;
  return config;
});

/** Get Api error redirect on login page */

rootApi.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    console.error(error)
    if (error.response.status === 401) {
      logOut();
    }
    return Promise.reject(error);
  }
);
